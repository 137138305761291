import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';

const Client = ({ name, width = [60, 60, 60] }) => {
  return (
    // <div
    //   css={css`
    //     max-width: 50%;
    //     ${Tablet} {
    //       max-width: 20%;
    //     }
    //     ${Desktop} {
    //       max-width: 20%;
    //     }
    //   `}
    // >
    //   <div
    //     css={css`
    //       width: ${width[0]}px;
    //       margin: 10px 0px;
    //       margin-right: 20px;
    //       ${Tablet} {
    //         width: ${width[1]}px;
    //         margin: 0px 25px;
    //       }
    //       ${Desktop} {
    //         width: ${width[2]}px;
    //         margin: 0px 30px;
    //       }
    //     `}
    //   >
    //     <img src={`/institutions/${name}`} alt="" width="100%" />
    //   </div>
    // </div>

    <div
      css={css`
        max-width: 45%;

        width: ${width[0]}px;
        margin: 10px 0px;

        ${Tablet} {
          max-width: 50%;
          width: ${width[1]}px;
          margin: 10px 20px;
        }
        ${Desktop} {
          // max-width: 20%;
          width: ${width[2]}px;
          margin: 10px 20px;
        }
      `}
    >
      <img src={`/institutions/${name}`} alt='' width='100%' />
    </div>
  );
};

export default Client;
