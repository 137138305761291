import React from "react";
import { Desktop, Tablet } from "../../constants/responsive";
import { css } from "@emotion/core";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import ScrollContainer from "react-indiana-drag-scroll";

const StartEndPadding = () => {
  return (
    <div
      css={css`
        flex: 0 0 auto;
        display: block;
        width: 20px;
        ${Tablet} {
          display: none;
        }
      `}
    ></div>
  );
};

const EndLabels = ({ children, background }) => {
  return (
    <div
      css={css`
        border-radius: 8px;
        background-color: ${background};
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        padding: 10px;
        ${Desktop} {
          padding: 0px;
          width: 120px;
          height: 70px;
          font-size: 20px;
        }
      `}
    >
      {children}
    </div>
  );
};

const InstitutionsSolutions = () => {
  return (
    <ScrollContainer
      css={css`
        justify-content: space-between;
        display: flex;
        width: 100%;
        padding: 0px;
        ${Tablet} {
          padding: 0px 20px;
          width: 768px;
        }
        ${Desktop} {
          width: 1200px;
        }
      `}
    >
      <StartEndPadding />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          flex-direction: column;
          width: 728px;
          flex: 0 0 728px;
          ${Tablet} {
            width: 100%;
          }
          ${Desktop} {
            flex: 0 0 1160px;
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            ${Desktop} {
              display: none;
            }
          `}
        >
          <EndLabels background="#ee2f78">
            <FormattedHTMLMessage id={`institutions.solutions.low`} />
          </EndLabels>{" "}
          <EndLabels background="#003e86">
            <FormattedHTMLMessage id={`institutions.solutions.high`} />
          </EndLabels>
        </div>

        <div
          css={css`
            display: none;
            ${Desktop} {
              display: block;
            }
          `}
        >
          <EndLabels background="#ee2f78">
            <FormattedHTMLMessage id={`institutions.solutions.low`} />
          </EndLabels>
        </div>
        <div>
          <div
            css={css`
              width: 100%;
              margin-top: 22px;
              height: 26px;
              border-radius: 4px;
              background-image: linear-gradient(
                to right,
                #ee2f78 15%,
                #003e86 84%
              );
              ${Desktop} {
                width: 840px;
              }
            `}
          ></div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              ${Desktop} {
                justify-content: center;
              }
            `}
          >
            {["bond", "balanced", "equity", "alternatives"].map((type) => {
              return (
                <div
                  key={type}
                  css={css`
                    width: fit-content;
                    height: fit-content;
                    border-radius: 8px;
                    border: solid 2px #003e86;
                    background-color: #ffffff;
                    padding: 15px;
                    margin: 12px 0px;
                    text-align: left;
                    color: #003e86;
                    ${Desktop} {
                      margin: 12px 5px;
                      padding: 20px;
                    }
                  `}
                >
                  <div
                    css={css`
                      font-weight: bold;
                      margin-bottom: 5px;
                      ${Tablet} {
                        font-size: 14px;
                      }
                      ${Desktop} {
                        font-size: 16px;
                      }
                    `}
                  >
                    <FormattedHTMLMessage
                      id={`institutions.solutions.${type}.title`}
                    />
                  </div>
                  {{
                    bond: [1, 2, 3],
                    balanced: [1, 2, 3, 4, 5],
                    equity: [1, 2, 3, 4],
                    alternatives: [1, 2, 3],
                  }[type].map((i) => {
                    return (
                      <div
                        key={i}
                        css={css`
                          font-size: 12px;
                          display: flex;
                          align-items: center;
                          ${Desktop} {
                            font-size: 14px;
                          }
                        `}
                      >
                        <div
                          css={css`
                            width: 6px;
                            height: 6px;
                            background-color: #003e86;
                            border-radius: 3px;
                            margin-right: 3px;
                            ${Desktop} {
                              margin-right: 6px;
                            }
                          `}
                        ></div>
                        <FormattedHTMLMessage
                          id={`institutions.solutions.${type}.point-${i}`}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div
          css={css`
            display: none;
            ${Desktop} {
              display: block;
            }
          `}
        >
          <EndLabels background="#003e86">
            <FormattedHTMLMessage id={`institutions.solutions.high`} />
          </EndLabels>
        </div>
      </div>
      <StartEndPadding />
    </ScrollContainer>
  );
};

export default InstitutionsSolutions;
